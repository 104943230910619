<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="mt-0">
        <v-img
          :src="require('@/assets/construction.svg')"
          contain
          :height="imgHeight"
        />
      </v-col>

      <v-col class="mb-4 text-center justify-center" cols="12">
        <h1 class="text-sm-h4 text-lg-h2 text-h6 justify-center text-center">
          {{ title }}
        </h1>
        <div class="text-md-body-1 body-2 pt-5 mw-600 mr-auto ml-auto">
          {{ text }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.mw-600 {
  max-width: 600px;
}
</style>
<script>
export default {
  name: "InDevelop",
  props: {
    title: {
      type: String,
      default: "Раздел в разработке",
    },
    text: {
      type: String,
      default: "",
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    imgHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 300;
        case "md":
          return 300;
        case "lg":
          return 400;
        case "xl":
          return 600;
      }
    },
  },
  data: () => ({}),
};
</script>
